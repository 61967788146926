import * as sdk  from "@sentry/browser";
import jsonSize  from "json-size";
import * as globals from "./globals.js"
import * as Integrations from "@sentry/integrations";
import {skipSentryError} from "Figured/Assets/Modules/sentryErrorSkiping";

/**
 * Set global sentry so that we don't create multiple instances if we import more than once.
 * This may not be necessary but I'd rather be safe knowing we have a singleton.
 *
 * @param {sdk}
 */
export let Sentry = (window.Sentry = window.Sentry || sdk);

/**
 * Vue props data is too large sometimes, which causes a 413.
 *
 * Use 100kb because Sentry's max is 200kb but we want to leave room for breadcrumbs etc.
 */
const maxPropsDataSize = 100 * 1024;

/**
 * Installs the global error event listener, or does nothing if Sentry should not be active.
 *
 * @param Vue
 */
export function install(Vue) {


    /**
     * This value is provided by the SENTRY_DSN_PUBLIC environment variable.
     */
    const dsn = globals.getSentryDsn();

    /**
     * Do not install the Sentry SDK if the dsn was not defined - warn if on production.
     */
    if (!dsn) {
        if (!globals.debug()) {
            console.warn("[Sentry] Logging is not active");
        }
        return;
    }

    Sentry.init({
        dsn: dsn,

        /**
         * This variable controls the total amount of breadcrumbs that should be captured. This defaults to 100.
         *
         * https://docs.sentry.io/error-reporting/configuration/?platform=javascript#max-breadcrumbs
         *
         * @type {number}
         */
        maxBreadcrumbs: 50,

        /**
         * This will be called before the event is sent to Sentry.
         *
         * https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-beforesend
         */
        beforeSend: (event, hint) => {
            const exception = _.get(hint, "originalException", null);

            if (hint && exception && skipSentryError(exception)) {
                return null;
            }

            if (jsonSize(_.get(event.extra, "propsData")) > maxPropsDataSize) {
                _.set(event.extra, "propsData", {});
            }

            return event;
        },

        /**
         * Add integrations here.
         *
         * https://docs.sentry.io/workflow/integrations
         */
        integrations: [
            new Integrations.Vue({ Vue }),
        ],

        /**
         * Get rid of those pesky (and useless) ResizeObserver loop exceeded errors.
         */
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications.",
        ],
    });

    Sentry.configureScope((scope) => {

        /**
         * Add user metadata if a user is defined (ie. logged in)
         */
        if (globals.getActiveUserId()) {
            scope.setUser({
                id:    globals.getActiveUserId(),
                email: globals.getActiveUserEmail(),
            })
        }

        const onRollingPlan = () => {
            if (!globals.getActiveFarmId()) {
                return "n/a";
            }

            return globals.onRollingPlan() ? "yes" : "no";
        };

        /**
         * Set farm and org metadata even if there is no active farm or active org.
         */
        scope.setExtra("farm_id", globals.getActiveFarmId());
        scope.setExtra("org_id",  globals.getActiveOrgId());
        scope.setExtra("on_rolling_plan",  onRollingPlan());
    });
}
